import React from 'react'
import './HeroStyles.css'
import LogoBV from '../../assets/LogoBomVentosSF.png'
import Zap from '../../assets/whatsapp_log.png'
//import Video from '../../assets/BarraGrande5.mp4'
import Video from '../../assets/penmarauJL.mov'


function Hero() {
    // const bgVideo = document.getElementById("bg-video");

    //     bgVideo.addEventListener("canplay", function() {
    //         bgVideo.style.opacity = 1;
    //     });

    return (
        <div className='hero'>
            <video className='fade-in' id='video' playsInline autoPlay loop muted >
                <source src={Video} type='video/mp4' />
            </video>
            <div className="overlay"></div>
            <div className="content">
                <div>
                    <h1>
                    <img className='imagem' src={LogoBV} alt="Logo-Bons-Ventos"/>
                    </h1>
                    
                </div>
                <h2>Natureza, Conforto, Aventura!</h2>
                <h3>Península de Maraú, Bahia</h3>
                </div>
                {window.name !== "hero" ? <div className="whats">
                    <a href="https://wa.me/5566999304248?text=Olá,%20gostaria%20de%20maiores%20informações%20sobre%20*Bons%20Ventos%20Camping%20e%20Glamping*.">
                        <img src={Zap} width="55px" alt="Fale Conosco pelo WhatsApp" title="Fale Conosco pelo WhatsApp" />
                    </a>
                </div> : null}
        </div>
    )
}

export default Hero
