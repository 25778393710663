import React from 'react'
import './ContatoStyles.css'
import { VscFoldUp } from "react-icons/vsc";
import { GrMapLocation } from "react-icons/gr";
import { GrPhone } from "react-icons/gr";


function Contato() {
    return (
        <div name='contato' className='contato'>
            <div className="container">
                <h2>Contato</h2>
                <div>
                    <div>
                        <h3>Reservas</h3>
                        <div>
                            Venha desfrutar momentos inesquecíveis. Faça sua reserva aqui! É só chamar no WhatsApp.
                        </div>
                        
                    </div>
                    <br/>
                    <div>
                        <h3>Localização</h3>
                        <div className="cont-respon">
                            <div>
                                <iframe 
                                    title="Bons Ventos Camping e Glamping"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15490.429446513337!2d-38.9425135!3d-13.9224017!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x73ebf7e08b7cc61%3A0x68cbfd2aa6c33d6f!2sBons%20Ventos%20Camping%20e%20Glamping!5e0!3m2!1spt-BR!2sbr!4v1675881089592!5m2!1spt-BR!2sbr" 
                                    width="350"
                                    height="300"
                                    style={{ border:"0" }}
                                    allowFullScreen="" 
                                    loading="lazy" 
                                    referrerPolicy="no-referrer-when-downgrade"                                    
                                    target="_blank">   
                                </iframe>
                            </div>
                            <div >
                                <div className="social-icons">
                                    <GrMapLocation className='icon'/> Av. Manoel Leite (Condminio Barra Grande - Qd. 45 Lt. 44), Barra Grande, BA, 45520-00
                                </div>
                                
                                <div className="social-icons">
                                    <GrPhone className='icon'/>(66) 99930-4248
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
            <div className="social-icons" id="canto-dir1">
                <a href="/"><VscFoldUp className='icon'/></a>
            </div>
        </div>
    )
}

export default Contato
