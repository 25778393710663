import React, { useState } from 'react'
import { HiOutlineMenuAlt4 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { TbBrandWhatsapp } from "react-icons/tb";
import { Link } from 'react-scroll'
import './NavbarStyles.css'

function Navbar() {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)

    return (
        <div name='home' className={nav ? 'navbar navbar-bg' : 'navbar'}>
            <div className={nav ? 'logo dark' : 'logo'}>
                {/* <a href="/"> */}
                    <h3>BONS VENTOS</h3>
                    <h5 className='h5text'>Camping e Glamping</h5>
                {/* </a> */}
            </div>
            <ul className="nav-menu">
                <Link to='home' smooth={true} duration={500} ><li>Início</li></Link>
                <Link to='sobre' smooth={true} duration={500} ><li>Sobre</li></Link>
                <Link to='glamping-carousel' smooth={true} duration={500} ><li>Glamping</li></Link>
                <Link to='camping' smooth={true} duration={500} ><li>Camping</li></Link>
                <Link to='experi' smooth={true} duration={500} ><li>Experiências</li></Link>
                <Link to='contato' smooth={true} duration={500} ><li>Contato</li></Link>
            </ul>
            <div className="nav-icons"> 
            </div>
            <div className="hamburger" onClick={handleNav}>
                {!nav ? (<HiOutlineMenuAlt4 className='icon' />) : (<AiOutlineClose style={{ color: '#000' }} className='icon' />)}

            </div>

            <div className={nav ? 'mobile-menu active' : 'mobile-menu'}>
                <ul className="mobile-nav">
                <Link to='home' smooth={true} duration={500} ><li>Início</li></Link>
                <Link to='sobre' smooth={true} duration={500} ><li>Sobre</li></Link>
                <Link to='glamping-carousel' smooth={true} duration={500} ><li>Glamping</li></Link>
                <Link to='camping' smooth={true} duration={500} ><li>Camping</li></Link>
                <Link to='experi' smooth={true} duration={500} ><li>Experiências</li></Link>
                <Link to='contato' smooth={true} duration={500} ><li>Contato</li></Link>
                </ul>
                <div className="mobile-menu-bottom">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/people/Bons-Ventos-Camping-e-Glamping/100078727423008/">
                            <FaFacebook className='icon' />
                        </a>
                        <a href="https://www.instagram.com/bonsventosglamping/">
                            <FaInstagram className='icon' />
                        </a>
                        <a href="https://wa.me/5566999304248?text=Olá,%20gostaria%20de%20maiores%20informações.">
                            <TbBrandWhatsapp className='icon' />
                        </a>
                    </div>
                </div>
            </div>
        </div>        
    )
}

export default Navbar
