import React from 'react'
import './CampingStyles.css'
import VideoCamping from '../../assets/IMG_9800.mp4'
import Sacada from '../../assets/sacada.jpg'
import CozinhaCamping from '../../assets/CozinhaCamping.jpg'

function Camping() {
    return (
        <div name='camping' className='camping'>
            <div className="container">
                <h2 className='padtop'>Camping</h2>
                <div>
                    <div>
                        <div className="cont-quadri">
                            <div>
                                <div className='esquerda'>
                                    <b>Camping</b> é a opção ideal para viajantes ávidos por aventura e que desejam viver uma 
                                    experiência única e inesquecível. Aproveite a natureza e sinta-se conectado à paisagem 
                                    enquanto desfruta de comodidade e conforto. Aqui você pode escapar do dia a dia e criar 
                                    memórias inesquecíveis com amigos e familiares. Com uma variedade de opções de acampamento, 
                                    desde tendas simples até acampamentos luxuosos, há algo para todos os gostos e orçamentos. 
                                    Então, junte suas coisas, pegue sua barraca e viva uma aventura emocionante no <b>Bons Ventos Camping e Glamping</b>.
                                </div>
                            
                            </div>
                            <div>
                                <div className='folga'>
                                    <video playsInline controls id='video-camping'  poster={Sacada}>
                                    <source src={VideoCamping} type='video/mp4' />
                                </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ajustado-baixo'>               
                    <img src={CozinhaCamping} alt="cozinha gourmet" className='imgsobre'/>
                    <b>Cozinha compartilhada do Camping</b>
                </div>     
            </div>
        </div>
    )
}

export default Camping
