import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.createRoot(document.getElementById('root')).render(
<React.StrictMode>
  <App />
</React.StrictMode>);

// O código acima foi a versão gerado pelo comando npm create-react-app mas está gerando o aviso abaixo

// Warning: ReactDOM.render is no longer supported in React 18. Use createRoot instead. 
// Until you switch to the new API, your app will behave as if it's running React 17.

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// reportWebVitals();