import React from 'react'
import './ExperiStyles.css'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Pic01 from '../../assets/pic01.jpg'
import Pic02 from '../../assets/pic02.jpg'
import Pic03 from '../../assets/pic03.jpg'
import Pic04 from '../../assets/pic04.jpg'
import Pic05 from '../../assets/pic05.jpg'
import Pic06 from '../../assets/pic06.jpg'
import Pic07 from '../../assets/Pic07.jpg'

const { mostrarEstatus } = true;
  
const statusFormatter = (current, total) => `${current} de ${total}`;


function Experi() {
    return (
        <div name='experi' className='experi' >
            <div className="container">
                <h2 className='padtop'>Experiências</h2>

                <div>
                    <div>
                        <div className="cont-quadri">
                            <div>
                            <div className='experi-carousel'>
                                <Carousel  
                                    showArrows={true} 
                                    autoPlay={true} 
                                    infiniteLoop={true} 
                                    interval={2000}
                                    // emulateTouch={true} 
                                    showThumbs={false} 
                                    showStatus={mostrarEstatus} statusFormatter={statusFormatter} 
                                    showIndicators={false}>
                                        <div className='experi-image'>
                                            <img src={Pic01} alt='camping' />
                                        </div>
                                        <div className='experi-image'>
                                            <img src={Pic02} alt='camping' />
                                        </div>
                                        <div className='experi-image'>
                                            <img src={Pic03} alt='camping' />
                                        </div>
                                        <div className='experi-image'>
                                            <img src={Pic04} alt='camping' />
                                        </div>
                                        <div className='experi-image'>
                                            <img src={Pic05} alt='camping' />
                                        </div>
                                        <div className='experi-image'>
                                            <img src={Pic06} alt='cozinha gourmet' />
                                        </div>
                                        <div className='experi-image'>
                                            <img src={Pic07} alt='cozinha gourmet' />
                                        </div>
                                        
                                </Carousel>
                            </div>
                            
                        </div>
                            <div className='desloca'>
                                <div className='direita'>
                                    No <b>Bons Ventos Camping e Glamping</b>, os viajantes podem vivenciar uma imersão na 
                                    natureza sem renunciar ao conforto. Aqui, nossos hóspedes têm a oportunidade de desfrutar 
                                    do mar, do vento e outros elementos da natureza em uma atmosfera autêntica 
                                    e relaxante. Com opções de acampamento que vão desde tendas até opções de glamping 
                                    luxuosas, há algo para todos os gostos e orçamentos. O camping é um lugar perfeito 
                                    para aventuras inesquecíveis com amigos e familiares. Aproveite a chance de escapar 
                                    da rotina e viver momentos inesquecíveis no Bons Ventos Camping e Glamping. 
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                   
            </div>
            <div className='conteudo'>
                    <h3>Faça um tour no Bons Ventos Camping e Glamping</h3>
                    {/* <iframe 
                        className='experi-video'
                        max-width="800" 
                        max-height="450" 
                        src="https://www.youtube.com/embed/PNGyOCr3WQI?si=0Vw_GyRnADP7Cetd" 
                        title="Vídeo Bons Ventos Camping e Glamping" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>

                    </iframe> */}
                    <div className='embed'>
                        <div className='tamanho'>
                            <iframe 
                                src="https://www.youtube.com/embed/PNGyOCr3WQI?si=0Vw_GyRnADP7Cetd" 
                                title="Vídeo Bons Ventos Camping e Glamping" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                                >
                            </iframe>
                        </div>
                        
                </div>                        
            </div>
        </div>
    )
}

export default Experi
