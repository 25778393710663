import React from 'react'
import './LocaquadriStyles.css'
import Quadri01 from '../../assets/Quadri01.jpg'
import Quadri02 from '../../assets/Quadri02.jpg'
// import Pessoa3 from '../../assets/Pessoa3.png'

function Locaquadri() {
    return (
        <div name='locaquadri' className='locaquadri'>
            <div className="container">
                <h2 className='padtop'>Locação de Quadriciclo</h2>
                Locamos quadriciclos de 570cc para passeios na Península de Maraú. Venha viver momentos inesquecíveis.
                <div>
                    <div>
                        <div className="cont-quadri">
                            <div>
                                <div>
                                    <img src={Quadri01} alt="quadriciclo" className='imgloca'/>
                                </div>
                            
                            </div>
                            <div>
                                <div>
                                    <img src={Quadri02} alt="quadriciclo" className='imgloca'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                
            </div>
        </div>
    )
}

export default Locaquadri
