import React from 'react'
import './SobreStyles.css'
import DeckPrincipal from '../../assets/DeckPrincipal.jpeg'

function Sobre() {
    return (
        <div name='sobre' className='sobre'>
            <div className="container">
                <div className='ajustado-titulo'>
                <h2>Sobre</h2>
                </div>
                
                <div className='ajustado'>
                    Desfrute da natureza, conforto e aventura no <b>Bons Ventos Camping e Glamping</b>, 
                    na Península de Maraú, Bahia. Com glamping e áreas para camping próximos das praias 
                    de corais mais deslumbrantes do Brasil.
                </div>
                <div className='ajustado-baixo'>
                    <img src={DeckPrincipal} alt='camping glamping' className='imgsobre'/>
                </div>
            </div>
        </div>
    )
}

export default Sobre
