import React from 'react'
//import ImgCarousel from './components/carousel/ImgCarousel';

import './App.css'
//import Zap from './assets/whatsapp_log.png'
import Sobre from './components/sobre/Sobre'
import Footer from './components/footer/Footer';
import Hero from './components/hero/Hero'
import Navbar from './components/navbar/Navbar'
import GlampCarousel from './components/glamping/GlampingCarousel';
import Camping from './components/camping/Camping';
import Experi from './components/experi/Experi';
import Contato from './components/contato/Contato';
import Locaquadri from './components/locaquadri/Locaquadri';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Sobre />
      <GlampCarousel />
      <Camping />
      <Experi />
      <Locaquadri />
      <Contato />
      <Footer />
    </div>    
  );
}

export default App;
