import React from 'react'
import './FooterStyles.css'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import { TbBrandWhatsapp } from "react-icons/tb";


function Footer() {
    return (
        <div className='footer'>
            <div className="container">
                <div className="top">
                    <h4>Redes Sociais</h4>
                    <div className="social">

                    <a href="https://www.facebook.com/people/Bons-Ventos-Camping-e-Glamping/100078727423008/">
                            <FaFacebook className='icon' />
                        </a>
                        <a href="https://www.instagram.com/bonsventosglamping/">
                            <FaInstagram className='icon' />
                        </a>
                        <a href="https://wa.me/5566999304248?text=Olá,%20gostaria%20de%20maiores%20informações%20sobre%20*Bons%20Ventos%20Camping%20e%20Glamping*.">
                            <TbBrandWhatsapp className='icon' />
                        </a>
                    </div>
                </div>
                <div className="bottom">
                    <div className="right">
                        <ul>
                            <li>Termos</li>
                            <li>Política</li>
                            <li>Privacidade</li>
                        </ul>
                    </div>
                    
                </div>
                
            </div>
            <div className="divcentro">
                <a className="corbtn" href="https://zanonsoft.com.br" target="_blank" rel="noreferrer">
                        zanonsoft
                </a>
            </div>
        </div>
    )
}

export default Footer
