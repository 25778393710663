import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Image from 'react-bootstrap/Image';

//import "reactjs-popup/dist/index.css";
import './GlampingCarouselStyles.css';
import "./index.css";

//import CozinhaGlamping from '../../assets/CozinhaGlamping.jpg'
import CozinhaGlamping from '../../assets/CozinhaGlamping1.jpg'
import GlampNatureza1 from '../../assets/GlampNatureza1.jpeg'
import GlampNatureza2 from '../../assets/GlampNatureza2.jpeg'
import GlampNatureza3 from '../../assets/GlampNatureza3.jpg'
import GlampNatureza4 from '../../assets/GlampNatureza4.jpg'
import GlampNatureza5 from '../../assets/GlampNatureza5.jpeg'
import GlampEncanto1 from '../../assets/GlampEncanto1.jpeg'
import GlampEncanto2 from '../../assets/GlampEncanto2.jpeg'
import GlampEncanto3 from '../../assets/GlampEncanto3.jpeg'
import GlampEncanto4 from '../../assets/GlampEncanto4.jpeg'
import GlampEncanto5 from '../../assets/GlampEncanto5.jpeg'
import GlampArcoIris1 from '../../assets/GlampArcoIris1.jpeg'
import GlampArcoIris2 from '../../assets/GlampArcoIris2.jpeg'
import GlampArcoIris3 from '../../assets/GlampArcoIris3.jpeg'
import GlampArcoIris4 from '../../assets/GlampArcoIris4.jpeg'
import GlampArcoIris5 from '../../assets/GlampArcoIris5.jpeg'

const images1 = [
    GlampNatureza1,
    GlampNatureza2,
    GlampNatureza3,
    GlampNatureza4,
    GlampNatureza5,
]

const images2 = [
    GlampEncanto2,
    GlampEncanto1,
    GlampEncanto3,
    GlampEncanto4,
    GlampEncanto5,
]

const images3 = [
    GlampArcoIris1,
    GlampArcoIris2,
    GlampArcoIris3,
    GlampArcoIris4,
    GlampArcoIris5,
]

const { mostrarEstatus } = true;
  
const statusFormatter = (current, total) => `${current} de ${total}`;



const MyPopup = ({ images }) => {
    const [key, setKey] = useState(0);
    //setKey(key + 1);
    const handleClick = () => {
      setKey(key + 1);
    };
  
    return (
      <Popup trigger={<Image src={images[0]} alt="glamping" fluid/>} modal closeOnDocumentClick overlayClassName="popup-overlay" lockScroll={true}>
        <div className="popup-content">
          <Carousel 
              key={key}
              showIndicators={false} 
              showArrows={true} 
              autoPlay={false} 
              infiniteLoop={true} 
              emulateTouch={true} 
              showThumbs={false} 
              showStatus={mostrarEstatus} statusFormatter={statusFormatter}
              >
            {images.slice(0).map((image) => (
              <div key={image}>
                  <Image src={image} alt="glamping" width='100%' />
              </div>
            ))}            
          </Carousel>
          O valor {handleClick}
        </div>        
      </Popup>      
    );
  };


const GlambingCarousel = () => {
    
  const [key, setKey] = useState(0);
   // eslint-disable-next-line
   const handleClick = () => {
      setKey(key + 1);
    };

    return (
        <div name='glamping-carousel' className='glamping-carousel' >
            <div className="container">
                <h2 className='padtop'>Glamping</h2>
                <h3>O que é Glamping?</h3>
                <div className="texto-glamping">
                    O glamping é uma nova tendência para aqueles que adoram aventura, 
                    mas sem renunciar ao conforto. O objetivo do glamping é fornecer uma experiência 
                    ao ar livre única, sem sacrificar as comodidades modernas, como cama confortável, 
                    banheiro privativo e cozinha gourmet compartilhada. Se você é um amante da natureza, mas não quer 
                    renunciar ao conforto, o glamping é a escolha perfeita para suas próximas férias. 
                    Venha descobrir essa nova maneira de experimentar a natureza em grande estilo!                          
                </div>
                <div  className="texto-glamping">
                  Navegue em nossos Glampings tocando nas imagens abaixo, veja os detalhes e escolha o seu. <br/>Faça sua reseva agora pelo Whatsapp! 
                </div>
            </div>
              <div>
                <div>
                    <div className="divglamping">
                      <div >
                            <button type='button' onClick={(handleClick)}>
                              <MyPopup images={images1}/>
                              <h3>Glamping Natureza</h3>
                            </button>
                          <button type='button' onClick={(handleClick)}>
                              <MyPopup images={images2}/>
                              <h3>Glamping Encanto</h3>
                          </button>
                          <button type='button' onClick={(handleClick)}>
                                <MyPopup images={images3}/>
                            <h3>Glamping Arco Iris</h3>
                          </button>
                      </div>                               
                    </div>                   
              </div> 
              <div className='ajustado-baixo'>               
                <img src={CozinhaGlamping} alt="deck-principal" className='imgsobre'/>
                <b>Cozinha gourmet compartilhada do Glamping</b>
              </div>                            
            </div> 
            
        </div>        
    )
}

export default GlambingCarousel
